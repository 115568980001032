export default {
  methods: {
    getCompanyLogo () {
      return process.env.VUE_APP_LOGO
    },
    getProfileLogo () {
      return process.env.VUE_APP_PROFILE_LOGO
    },
    getCurrentDateTime () {
      const currentdate = new Date()
      return currentdate.toLocaleString()
    }
  }
}
