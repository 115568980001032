<template>
  <b-container
    class="mb-5"
    fluid
  >
    <b-breadcrumb
      v-if="$route.meta.breadcrumb"
      :items="$route.meta.breadcrumb || []"
    />
    <b-card>
      <b-row class="mb-2">
        <b-col
          cols="12"
        >
          <b-form-group>
            <label>Search String</label>
            <b-input
              v-model="tableSettings.filter"
              type="text"
              placeholder="Enter search string"
              debounce="1000"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-table
        ref="table"
        hover
        responsive
        class="mt-2"
        :per-page="tableSettings.perPage"
        :current-page="tableSettings.currentPage"
        :items="myProvider"
        :fields="tableSettings.fields"
        :sort-by.sync="tableSettings.sortBy"
        :sort-desc.sync="tableSettings.sortDesc"
        :sort-direction="tableSettings.sortDirection"
        :filter="tableSettings.filter"
        :filter-included-fields="tableSettings.filterOn"
        show-empty
      >
        <template #cell(index)="data">
          {{ tableSettings.currentPage * tableSettings.perPage - tableSettings.perPage + (data.index + 1) }}
        </template>

        <template #cell()="data">
          <div class="text-nowrap">
            {{ data.value }}
          </div>
        </template>

        <template #cell(actions)="row">
          <div class="text-nowrap">
            <b-button
              size="sm"
              @click="showProcessForm(row.item)"
            >
              Approval
            </b-button>
          </div>
        </template>

      </b-table>

      <b-row>
        <b-col
          cols="12"
          sm="6"
        >
          <div class="w-100 w-sm-25 mb-2 sm-mb-2">
            <b-select
              v-model="tableSettings.perPage"
              :options="tableSettings.pageOptions"
              size="sm"
            />
          </div>
        </b-col>
        <b-col
          cols="12"
          sm="6"
          class="d-flex justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="tableSettings.currentPage"
            :total-rows="tableSettings.totalRows"
            :per-page="tableSettings.perPage"
            first-number
            last-number
            pills
            prev-text="Prev"
            next-text="Next"
            aria-controls="table"
          />
        </b-col>
      </b-row>
    </b-card>

    <!-- modal process form -->
    <b-modal
      id="modal-process-form"
      scrollable
      no-close-on-backdrop
      title="Approval"
      size="xl"
      @ok="onConfirmAction"
    >
      <form
        novalidate
        role="form"
      >
        <b-card
          no-body
        >
          <b-container fluid>
            <b-row>
              <b-col
                cols="12"
                sm="8"
              >
                <b-row>
                  <b-col cols="12">
                    <strong>Payee:</strong> <span style="text-decoration: underline;">{{ paymentRequest.request_payee }}</span>
                  </b-col>
                  <b-col cols="12">
                    <strong>Amount:</strong>  <span style="text-decoration: underline;">P{{ paymentRequest.request_amount }}</span>
                  </b-col>
                </b-row>
              </b-col>
              <b-col
                cols="12"
                sm="4"
              >
                <b-row>
                  <b-col cols="12">
                    <strong>Date:</strong> <span style="text-decoration: underline;">{{ (paymentRequest.created_at || '').split(' ')[0] }}</span>
                  </b-col>
                  <b-col cols="12">
                    <strong>Doc No:</strong> <span style="text-decoration: underline;">{{ paymentRequest.document_number }}</span>
                  </b-col>
                </b-row>
              </b-col>
              <b-col
                cols="12"
                sm="8"
              >
                <b-row class="d-none d-sm-block">
                  <b-col cols="12">
                    <strong>Mode of Payment:</strong> <span style="text-decoration: underline;">{{ paymentRequest.payment_mode }}</span>
                  </b-col>
                  <b-col cols="12">
                    <strong>If For Deposit</strong>
                  </b-col>
                </b-row>
              </b-col>
              <b-col
                cols="12"
                sm="4"
              >
                <b-row>
                  <b-col cols="12">
                    <strong>Budget:</strong> <span style="text-decoration: underline;">{{ paymentRequest.budget_code }}</span>
                  </b-col>
                  <b-col cols="12">
                    <strong>Actual:</strong> <span style="text-decoration: underline;">{{ paymentRequest.budget_actual_code }}</span>
                  </b-col>
                </b-row>
              </b-col>
              <b-col cols="12">
                <b-row>
                  <b-col
                    cols="12"
                    class="d-sm-none d-block"
                  >
                    <strong>Mode of Payment:</strong> <span style="text-decoration: underline;">{{ paymentRequest.payment_mode }}</span>
                  </b-col>
                  <b-col
                    cols="12"
                    class="d-sm-none d-block"
                  >
                    <strong>If For Deposit</strong>
                  </b-col>
                  <b-col cols="12">
                    <strong class="ml-3">Account Number:</strong> <span style="text-decoration: underline;">{{ paymentRequest.bank_account_number }}</span>
                  </b-col>
                  <b-col cols="12">
                    <strong class="ml-3">Account Name:</strong>  <span style="text-decoration: underline;">{{ paymentRequest.bank_account_name }}</span>
                  </b-col>
                  <b-col cols="12">
                    <strong class="ml-3">Bank:</strong> <span style="text-decoration: underline;">{{ paymentRequest.bank }}</span>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>

            <hr>

            <b-row>
              <b-col
                cols="12"
                sm="4"
              >
                <strong>PARTICULARS / PURPOSE</strong>
              </b-col>
              <b-col
                cols="12"
                sm="8"
              >
                <p>{{ (paymentRequest.request_description || '').toUpperCase() }}</p>
              </b-col>
            </b-row>

            <hr>

            <b-row>
              <b-col
                cols="12"
                sm="4"
              >
                <strong>BUDGET REMARKS</strong>
              </b-col>
              <b-col
                cols="12"
                sm="8"
              >
                <p>{{ (paymentRequest.approval_three_remarks || '').toUpperCase() }}</p>
              </b-col>
            </b-row>

            <hr v-if="paymentRequest.file_list.length > 0">

            <b-row v-if="paymentRequest.file_list.length > 0">
              <b-col
                cols="12"
                class="mb-2"
              >
                <strong>ATTACHMENTS</strong>
              </b-col>
              <b-col cols="12">
                <b-button
                  v-if="paymentRequest.file_list.length>0"
                  size="sm"
                  variant="success"
                  class="mb-2"
                  @click="onPreviewAllAttachment"
                >
                  Preview All
                </b-button>
              </b-col>
              <b-col cols="12">
                <b-table
                  :fields="attachmentTableSettings.fields"
                  :items="paymentRequest.file_list"
                  responsive
                  striped
                  hover
                  fixed
                >
                  <template #cell(actions)="row">
                    <div class="text-nowrap">
                      <b-button
                        size="sm"
                        class="mr-1"
                        :disabled="isPreviewing"
                        @click="onPreviewAttachment(row.item)"
                      >
                        Preview
                      </b-button>
                    </div>
                  </template>
                </b-table>
              </b-col>
            </b-row>

            <hr>

            <b-row>
              <b-col
                cols="12"
                sm="6"
              >
                <b-row>
                  <b-col cols="12">
                    <strong>REQUESTED BY:</strong>
                  </b-col>
                  <b-col cols="12">
                    <span style="text-decoration: underline;">{{ `${(paymentRequest.user_name || '').toUpperCase()}` }}
                    </span></b-col>
                  <b-col cols="12">
                    <span>{{ `${paymentRequest.created_at}` }}</span>
                  </b-col>
                </b-row>
              </b-col>
              <b-col
                cols="12"
                sm="6"
                class="mt-5 mt-sm-0"
              >
                <b-row>
                  <b-col cols="12">
                    <strong>NOTED BY:</strong>
                  </b-col>
                  <b-col cols="12">
                    <span style="text-decoration: underline;">{{ `${(paymentRequest.approval_one_name || '').toUpperCase()}` }}</span> {{ `[${(paymentRequest.approval_one_status || '').toUpperCase()}]` }}
                  </b-col>
                  <b-col cols="12">
                    {{ `${paymentRequest.approval_one_at || ''}` }}
                  </b-col>
                </b-row>
              </b-col>
            </b-row>

            <b-row class="mt-5">
              <b-col cols="12">
                <ValidationObserver
                  ref="form"
                >
                  <form
                    novalidate
                    role="form2"
                  >
                    <ValidationProvider
                      #default="{ errors }"
                      name="approver remarks"
                      vid="approval_remarks"
                      rules=""
                    >
                      <b-form-group>
                        <label for="approver_remarks"><strong>Approver Remarks</strong></label>
                        <b-form-textarea
                          id="approver_remarks"
                          v-model="current.remarks"
                          rows="6"
                          max-rows="6"
                          :state="errors.length > 0 ? false : null"
                          placeholder="enter approver remarks"
                          autocomplete="off"
                          :disabled="isBusy"
                        />
                        <div
                          v-if="errors.length > 0"
                          class="invalid-feedback"
                        >
                          <span>{{ errors[0] }}</span>
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                  </form>
                </ValidationObserver>
              </b-col>
            </b-row>
          </b-container>
        </b-card>
      </form>

      <template #modal-footer="{ok, cancel}">
        <b-button
          variant="success"
          :disabled="isBusy"
          @click="onSetAction(ok, 'Approve', 'Approved')"
        >
          Approved
        </b-button>

        <b-button
          variant="secondary"
          :disabled="isBusy"
          @click="onSetAction(ok, 'Disapprove', 'Disapproved')"
        >
          Disapproved
        </b-button>

        <b-button
          class="d-none d-sm-block"
          variant="danger"
          :disabled="isBusy"
          @click="cancel()"
        >
          Close Window
        </b-button>
      </template>
    </b-modal>

    <!-- modal -->
    <b-modal
      id="modal-preview-pdf"
      scrollable
      no-close-on-backdrop
      :title="`Preview Attachment [${previewFilename}]`"
      size="lg"
    >
      <vue-pdf-embed :source="preview" />

      <template #modal-footer="{cancel}">
        <b-button
          variant="danger"
          @click="cancel()"
        >
          Close Window
        </b-button>
      </template>
    </b-modal>

    <!-- modal -->
    <b-modal
      id="modal-preview-image"
      scrollable
      no-close-on-backdrop
      :title="`Preview Attachment [${previewFilename}]`"
      size="lg"
    >
      <b-img
        fluid
        :src="preview"
      />

      <template #modal-footer="{cancel}">
        <b-button
          variant="danger"
          @click="cancel()"
        >
          Close Window
        </b-button>
      </template>
    </b-modal>

    <!-- modal -->
    <b-modal
      id="modal-preview-all-image"
      scrollable
      no-close-on-backdrop
      :title="`Preview Attachments`"
      size="lg"
    >
      <div>
        <b-img
          v-for="attachment in allAttachments"
          :key="attachment"
          fluid
          :src="attachment"
        />
      </div>

      <template #modal-footer="{cancel}">
        <b-button
          variant="danger"
          @click="cancel()"
        >
          Close Window
        </b-button>
      </template>
    </b-modal>

  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed'
import { AxiosError, UserPendingApprovalService, UserActionService } from '@/services'
import formatter from '@/mixins/formatter'
import url from '@/mixins/url'
import misc from '@/mixins/misc'

export default {
  name: 'UserPendingApproval',

  middleware: ['auth', 'user'],

  metaInfo () {
    return {
      title: 'Pending Approvals'
    }
  },

  components: {
    VuePdfEmbed
  },

  mixins: [formatter, url, misc],

  data () {
    return {
      isBusy: false,
      isPreviewing: false,
      preview: null,
      previewFilename: '',
      allAttachments: [],
      current: {
        action: '',
        status: '',
        remarks: ''
      },
      paymentRequest: {
        id: 0,
        created_at: '',
        request_payee: '',
        request_amount: 0,
        transaction_type: '',
        budget_code: '',
        budget_name: '',
        budget_actual_code: '',
        budget_actual_name: '',
        item_name: '',
        request_description: '',
        payment_mode: '',
        payment_action: '',
        bank: '',
        bank_account_number: '',
        bank_account_name: '',
        user_name: '',
        approval_one_id: 0,
        approval_one_name: '',
        approval_one_status: '',
        approval_one_at: '',
        approval_one_remarks: '',
        approval_two_id: 0,
        approval_two_name: '',
        approval_two_status: '',
        approval_two_at: '',
        approval_two_remarks: '',
        approval_three_remarks: '',
        payment_nature: '',
        document_type: '',
        receipt_type: '',
        supplier_type: '',
        trade_name: '',
        company_name: '',
        proprietor_first_name: '',
        proprietor_middle_name: '',
        proprietor_last_name: '',
        tin: '',
        address: '',
        file_path: '',
        file_list: []
      },
      tableSettings: {
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        totalRows: 0,
        currentPage: 1,
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: '',
        filterOn: [],
        fields: [
          'index',
          'actions',
          { key: 'created_at', formatter: this.dateTimeFormatter, label: 'request date', sortable: true },
          { key: 'request_payee' },
          { key: 'request_amount', formatter: this.numberFormatter, class: 'text-right' },
          { key: 'request_status' },
          { key: 'user_name', label: 'requested by' },
          { key: 'approval_type' },
          { key: 'approval_status' }
        ]
      },
      attachmentTableSettings: {
        fields: [
          'actions',
          { key: 'file_original_name', label: 'filename' }
        ]
      }
    }
  },

  mounted () {
    core.index()
  },

  methods: {
    async myProvider (ctx) {
      try {
        const { data } = await UserPendingApprovalService.get(`page=${ctx.currentPage}&per_page=${ctx.perPage}&sort=${ctx.sortBy}&sort_desc=${ctx.sortDesc}&filter_text=${ctx.filter}`)
        this.tableSettings.totalRows = data.total_rows
        return data.items
      } catch {
        return []
      }
    },

    onSetAction (callback, action, status) {
      this.current.action = action
      this.current.status = status
      callback()
    },

    showProcessForm (item) {
      this.paymentRequest.id = item.id
      this.paymentRequest.created_at = this.dateTimeFormatter(item.created_at)
      this.paymentRequest.request_payee = item.request_payee
      this.paymentRequest.request_amount = this.numberFormatter(item.request_amount)
      this.paymentRequest.transaction_type = item.transaction_type
      this.paymentRequest.request_status = item.request_status
      this.paymentRequest.budget_code = item.budget_code
      this.paymentRequest.budget_name = item.budget_name
      this.paymentRequest.budget_actual_code = item.budget_actual_code
      this.paymentRequest.budget_actual_name = item.budget_actual_name
      this.paymentRequest.item_name = item.item_name
      this.paymentRequest.request_description = item.request_description
      this.paymentRequest.payment_mode = item.payment_mode
      this.paymentRequest.payment_action = item.payment_action
      this.paymentRequest.bank = item.bank
      this.paymentRequest.bank_account_number = item.bank_account_number
      this.paymentRequest.bank_account_name = item.bank_account_name
      this.paymentRequest.request_description = item.request_description
      this.paymentRequest.user_name = item.user_name
      this.paymentRequest.approval_one_id = item.approval_one_id
      this.paymentRequest.approval_one_name = item.approval_one_name
      this.paymentRequest.approval_one_status = item.approval_one_status
      this.paymentRequest.approval_one_at = item.approval_one_at
      this.paymentRequest.approval_one_remarks = item.approval_one_remarks
      this.paymentRequest.approval_two_id = item.approval_two_id
      this.paymentRequest.approval_two_name = item.approval_two_name
      this.paymentRequest.approval_two_status = item.approval_two_status
      this.paymentRequest.approval_two_at = item.approval_two_at
      this.paymentRequest.approval_two_remarks = item.approval_two_remarks
      this.paymentRequest.approval_three_remarks = item.approval_three_remarks
      this.paymentRequest.payment_nature = item.payment_nature
      this.paymentRequest.document_type = item.document_type
      this.paymentRequest.receipt_type = item.receipt_type
      this.paymentRequest.supplier_type = item.supplier_type
      this.paymentRequest.trade_name = item.trade_name
      this.paymentRequest.company_name = item.company_name
      this.paymentRequest.proprietor_first_name = item.proprietor_first_name
      this.paymentRequest.proprietor_middle_name = item.proprietor_middle_name
      this.paymentRequest.proprietor_last_name = item.proprietor_last_name
      this.paymentRequest.tin = item.tin
      this.paymentRequest.address = item.address
      this.paymentRequest.file_path = item.file_path
      this.paymentRequest.file_list = item.attachments
      this.current.status = ''
      this.current.action = ''
      this.current.approval_remarks = ''
      this.$bvModal.show('modal-process-form')
    },

    async onConfirmAction (bvModalEvt) {
      bvModalEvt.preventDefault()
      await this.$refs.form.validate().then(async success => {
        if (success) {
          this.$swal.fire({
            icon: 'question',
            title: `Are you sure you want to ${this.current.action} this payment request?`,
            showCancelButton: true,
            confirmButtonColor: '#06C270',
            confirmButtonText: 'Confirm',
            cancelButtonColor: '#FF2929',
            showLoaderOnConfirm: true,
            preConfirm: async () => {
              return this.onPostFormSubmit()
            },
            allowOutsideClick: () => !this.$swal.isLoading()
          })
        } else {
          this.$bvModal.msgBoxOk('Oppss! There were problem with your inputs.', {
            title: 'Validation Error',
            size: 'sm',
            buttonSize: 'sm',
            okVariant: 'danger'
          })
        }
      })
    },

    async onPostFormSubmit () {
      return new Promise((resolve, reject) => {
        const params = {
          id: this.paymentRequest.id,
          approval_status: this.current.status,
          approval_remarks: this.current.remarks
        }

        this.isBusy = true
        UserActionService.postRequestApproval(params).then(response => {
          this.isBusy = false
          this.$bvModal.hide('modal-process-form')
          this.$swal.fire({
            icon: 'success',
            title: 'Successful',
            text: response.data.message,
            confirmButtonColor: '#06C270',
            confirmButtonText: 'Dismiss'
          }).then(() => {
            this.$refs.table.refresh()
          })
        }).catch(error => {
          this.isBusy = false
          if (error instanceof AxiosError) {
            if (error.code === 422) {
              this.$refs.form.setErrors(error.message)
              this.$bvModal.msgBoxOk('Oppss! There were problem with your inputs.', {
                title: 'Validation Error',
                size: 'sm',
                buttonSize: 'sm',
                okVariant: 'danger'
              })
              resolve(error)
            }
          }
        })
      })
    },

    async onPreviewAttachment (item) {
      this.previewFilename = item.file_original_name
      this.preview = this.getImageUrl(`${this.paymentRequest.file_path}/${item.file_hash_name}`)
      this.$bvModal.show('modal-preview-image')
    },

    onPreviewAllAttachment () {
      this.allAttachments = []

      this.paymentRequest.file_list.forEach(file => {
        this.allAttachments.push(this.getImageUrl(`${this.paymentRequest.file_path}/${file.file_hash_name}`))
      })

      this.$bvModal.show('modal-preview-all-image')
    }
  }
}
</script>
